#metalon{
    display: flex;
    flex-direction: column;
    align-items: center;
}

#metalon .title{ 
    margin: 30px;
    font-size: 30px;
    font-weight: 500;
}

#metalon .copy{
    font-size: 20px;
    width: 600px;
    max-width: 90%;
    text-align: center;

    margin-bottom: 50px;
}

.ctaButton{
    padding: 20px 50px;
    background-color: black;
    color: white;
    border: 3px solid transparent;
    text-decoration: none;
    margin: 30px 0 50px;
    font-weight: 500;
    border-radius: 5px;
}

.ctaButton:hover{
    border-color: black;
    background-color: transparent;
    color: black;
}
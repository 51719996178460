@media (max-width: 600px) {
    #about .text {
        width: 100% !important;
    }
}

#about {
    display: flex;
    flex-direction: column;
    padding-top: 0;
    background-repeat: no-repeat;
    background-position-y: 50%;
    background-position-x: 50%;
    position: relative;
    background-image: url('../../../imgs/loja.jpeg');
    background-size: cover;
}

#about .title{
    display: flex;
    justify-content: center;
    color: var(--preto);
    background-color: rgba(255,255,255,0.5);
    width: 100%;
    padding: 30px;
    font-size: 30px;
    font-weight: bold;
    z-index: 1;
}

#about .container{
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(255,255,255,0.5);
    text-align: center;
    max-width: 100%;
    width: 100%;
    height: 100%;
    padding: 20px;
    z-index: 1;
}

#about .btn{
    margin: 20px 0;
}

#about .btnAbout:hover{
    color: white;
}

#about .text {
    font-size: 18px;
    font-weight: 700;
    text-shadow: 0px 0px 0px black;
    color: black;
    width: 50%;
}

#about .ctaText{
    font-weight: 700;
    font-size: 20px;
}

#about .gradient{
    width: 100%;
    height: 100%;
    position: absolute;
    background-image:  linear-gradient(110deg, var(--azul) 10%, transparent 100%);
}
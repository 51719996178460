@media (max-width: 600px) {
    #footer {
        flex-direction: column;
        align-items: center;
    }
    #footer .logo{
        margin: 20px 0;
    }
}

#footer {
    width: 100%;
    padding-top: 50px;
    display: flex;
    justify-content: space-evenly;
    background-color: gray;
    height: max-content;
    color: white;
}

#footer .logo{
    width: 200px;
    max-width: 100%;
}
#footer .section{
    display: flex;
    flex-direction: column;
    width: 200px;
}
#footer .sectionTitle{
    font-size: 20px;
    margin-bottom: 20px
}

#footer .text{
    font-size: 15px;
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.lastLine{
    background-color: rgba(0, 0, 0, 0.849) !important;
    color: gray;
    display: flex;
    justify-content: center;
    width: 100%;
}

#footer .instaLogo{
    width: 25px;
    margin: 0 5px;
}

#footer .link {
    cursor: pointer;
    text-decoration: none;
    color: white;
}
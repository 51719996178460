#products{
    min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#products .title{
    font-size: 35px;
    color: var(--preto);
    margin: 50px;
    font-weight: 500;
}

#products .container{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

}

#products .productContainer{
    display: flex;
    flex-direction: column;
    border: 5px solid red;
    box-shadow: 1px 1px 50px rgba(31, 30, 30, 0.178);
    align-items: center;
    height: 400px;
    flex-basis: 15%;
    border-radius: 10px;
    max-width: 100%;
    margin: 5px;
    margin-bottom: 50px;
}
#products .productLink{
    text-decoration: none;
    background-color: var(--preto);
    border: 2px solid transparent;
    color: white;
    padding: 10px 20px;
    margin: 20px 0 40px;
    transition: all 0.3s;
}

#products .titleLink{
    text-decoration: none;
    color: white;
}

#products .productLink:hover{
    cursor: pointer;
    background-color: transparent;
    color: var(--preto);
    border-color: var(--preto);
}

#products .productTitle {
    text-align: center;
    width: 100%;
    font-size: 20px;
    padding: 10px 0;
    background-color: red;
}

#products .productImage{
    margin: 20px;
    width: 250px;
    max-height: 200px;
    object-fit: cover;
    flex: 1;
    border-radius: 10px;
}

#products .btnProducts{
    margin-bottom: 40px;
}
.galeryImage{
    width: 300px;
}

.galeryCarousel{
    margin: 10px 0 50px 0;
}

.slide{
    display: flex ;
    align-items: center;
    justify-content: center ;
}

.imgsContainer{
    width: 355px;
}

.galeryImage{
    border-radius: 10px;
}
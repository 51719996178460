.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.card {
    width: 250px;
    height: 300px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    border-radius: 10px;
    margin: 30px;
    padding: 25px 10px 0;

    box-shadow: 1px 1px 50px rgba(0, 0, 0, 0.199);
}

.cardTitle {
    font-size: 22px;
    font-weight: 500;
    margin: 10px 0;
}

.cardText{
    margin: 20px;
    font-size: 18px;
}
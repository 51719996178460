@media (max-width: 600px) {
    #home .btn{
        margin: 80px;
    }
}

#home {
    position: relative;
    max-width: 100% !important;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 85vh;
}

#home .logo {
    width: 600px;
    max-width: 90%;
    padding: 10px;
}
#home .slogan2 {
    margin: 0px;
    color: black;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
}
#home .slogan1 {
    margin: 10px;
    color: black;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
}

.btn {
    margin: 10px 0;
    background-color: var(--vermelho);
    padding: 15px;
    border-radius: 5px;
    color: white;
    cursor: pointer;
    border: 3px solid transparent;
    font-weight: 500;
    transition: all 0.3s;
    width: max-content;
    height: max-content;
}

.btn:hover {
    background-color: transparent;
    border-color:  var(--vermelho);
    color: black;
}

.whatsLink{
    text-decoration: none;
    color: black;
}

#home .fab{
    width: 50px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
}

#home .gradient{
    width: 100%;
    min-height: 100vh;
    position: absolute;
    background-color: var(--cinza);
    background-image: url('../../../imgs/bg3.jpg');
    background-size: cover;
    background-repeat: no-repeat;
}

#home .logoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}


@media (max-width: 600px) {
    #header{
        flex-direction: column;
        align-items: center;
    }

    #header .minilogo{
        margin-top: 15px;
        border-radius: 20px !important;
        width: 150px !important;
        padding: 10px 20px !important;
    }
    #header .topBar{
        padding: 20px 30px 10px;
        text-align: center;
    }
}

#header{
    display: flex;
    width: 100%;
    z-index: 999;
    background-color: var(--preto);
    display: flex;
    padding: 5px 0;
    justify-content: space-around
}

#header .text {
    text-align: center;
    color: black
}

#header .topBar{
    z-index: 1;
    display: flex;
    justify-content: center;
    color: white;
}

#header .minilogo{
    width: 100px;
    background-color: white;
    padding: 10px 10px;
    border-radius: 10px;
    border-top-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

#header .pageLink{
    margin: 5px;
    padding: 5px;
    text-decoration: none;
    color: white;
    display: flex;
    align-items: center;
}
#header .pageLink:hover{
    border-bottom: 2px solid var(--vermelho);
}